import { useNavigate } from "react-router-dom";
import { useAuth } from "../provider/authProvider";
import React, { useState,useEffect  } from 'react';
import axios from 'axios';
import config from '../config';

const Login = () => {
  const { setToken } = useAuth();
  const navigate = useNavigate();

  const { token } = useAuth(); // Adjust according to your auth context/state management
  useEffect(() => {
    // Define the configuration object
   
  }, []);

  const [mobileNumber, setMobileNumber] = useState('');
  const [isValid, setIsValid] = useState(true);
  const [otp, setOtp] = useState('');
  const [showOtpField, setShowOtpField] = useState(false);
  const [isMobileValid, setIsMobileValid] = useState(true);
  const [error, setError] = useState('');
  const [otpError,setOtpError] = useState('');
  const handleMobileNumberChange = (event) => {
      const newMobileNumber = event.target.value;
   
      setMobileNumber(newMobileNumber); 
      setIsValid(/^[6789]\d{9}$/.test(newMobileNumber));
     
  };
  const validatePhoneNumber = (number) => {
    if (number.trim() === '') {
      return 'Phone number is required.';
    }
    // Indian mobile numbers start with 7, 8, 9, or 6 and are 10 digits long.
    const regex = /^[6789]\d{9}$/;
    return regex.test(number) ? '' : 'Please enter a valid  mobile number.';
  };
 
  const handleGenerateOtp = async (e) => {
    e.preventDefault();
    const validationError = validatePhoneNumber(mobileNumber);
    if (validationError) {
      setError(validationError);
    } else {
      setError('');
      try {
        const configuration = {
          widgetId: '34676266586d313230343231', // Replace with your widget ID
          tokenAuth: '425533AIuS52NjVS7Q66951fc9P1', // Replace with your actual token
          identifier: mobileNumber, // You can set the mobile number/email here
          exposeMethods: true,
          captchaRenderId: 'captcha-container', // ID of the element where to render captcha
          success: (data) => {
            // get verified token in response
            console.log('success response', data);
          },
          failure: (error) => {
            // handle error
            console.log('failure reason', error);
          },
        };
    
        // Ensure the script is loaded before initializing
        const script = document.createElement('script');
        script.src = 'https://control.msg91.com/app/assets/otp-provider/otp-provider.js';
        script.onload = () => {
          if (window.initSendOTP) {
            window.initSendOTP(configuration);
          } else {
            console.error('MSG91 OTP provider script not loaded');
          }
        };
        document.body.appendChild(script);
        const response = await axios.post(`${config.backendUrl}/admin/generate_Otp`, {
          "mobile":mobileNumber
      
      });
      setShowOtpField(true);
      
        
      } catch (error) {
        setError(error.response.data.message);
      }
    }
    };
  const handleOtpChange = (event) => {
      const newOtp = event.target.value;
      setOtp(newOtp); 
      setIsValid(newOtp.length === 6);  
  };

  const handleSubmit = async (event) => {
    event.preventDefault(); 
    console.log('Verifying OTP:', otp);
    try {

      const response = await axios.post(`${config.backendUrl}/admin/validate_Otp`, {
        "mobile":mobileNumber,
        "otp":otp,
       
    });
    setToken(response.data.Token);
    console.log("response",response);
    navigate('/admin/dashboard'); 
    setShowOtpField(true);
    
      
    } catch (error) {

      
      console.error('Error sending OTP:', error);
      setOtpError(error.response.data.message);

    }
   
  };
  return (
    <div className='login-container'> 
    
    <div className='logoheader flex justify-center'><img style={{height:"70px"}} src='../images/dilips-logo.png' alit="logo" />  </div>
    <form style={{background:"#fff",width:"100%"}} onSubmit={handleSubmit}>
        <h2 className='flex justify-center'>Login</h2>
        <div className='loginbody'> 
        <h3>Welcome</h3>
        <p className='font11 mb-4'>Please login to loyalty program by DILIPS INDIA</p>
      {showOtpField ? (
        <>
        <div> 
            <div className='mb-2'> 
            <input
                type="text"
                value={mobileNumber}
                onChange={handleMobileNumberChange}
                placeholder="Mobile Number"
                disabled  
            />
            </div>
            <div className='mb-2'> 
            <input
                type="text"
                value={otp}
                onChange={handleOtpChange}
                placeholder="OTP"
                className={isValid ? '' : 'input-invalid'}  
                required
            />
             {otpError && <div style={{ color: 'red', marginTop: '8px' }}>{otpError}</div>}
            </div>
          </div>
          <div className='text-right'><button className='redbtn' type="submit">Submit</button></div> 
        </>
      ) : (
        <div> 
            <div className='mb-2'> 
        <input
          type="text"
          value={mobileNumber}
          onChange={handleMobileNumberChange}
          placeholder="Mobile Number"
          className={isMobileValid ? '' : 'input-invalid'}  
          required
        />
        </div>
        {error && <div style={{ color: 'red', marginTop: '8px' }}>{error}</div>}

       <div className='text-right'> <button type="button"  className='redbtn' onClick={handleGenerateOtp}>Generate OTP</button></div> 
        </div>
      )}
      </div>
    </form>
  <p className='flex justify-center mt-3'> Dilipsindia Loyalty Programme</p>
    </div>
  );
};

export default Login;